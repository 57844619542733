namespace eh {

  export class Forms {

    private static qRequested = 15;
    private static reqQ(fromTimer?:boolean) {
      if( Forms.qRequested > 0) {
        if(!!(window._elqQ) && typeof window._elqQ.push === 'function') {
          Forms.qRequested = 0;
          window._elqQ.push(['elqGetCustomerGUID']);
          return;
        }
        if(!!(fromTimer)) {
          Forms.qRequested -= 1;
          window.setTimeout(Forms.reqQ, 250, true);
        }
      }
    }

    private static elqSetValue($fields:JQuery<HTMLElement>, iter: number):void {
      if($fields.length > 0) {
        if(typeof window.GetElqCustomerGUID === 'function') {
          $fields.val(window.GetElqCustomerGUID());
          return;
        }
        if (iter > 0) {
          Forms.reqQ();
          iter -= 1;
          setTimeout(Forms.elqSetValue, 400, $fields, iter);
        }
      }

    }
    private static scrollCenterDesktop(el: HTMLElement) {
      let offset = el.getBoundingClientRect().top; // offset in elPaWa
      let elHi = el.getBoundingClientRect().height;
      var windowHeight = $(window).height() || 0;
      if(elHi < (windowHeight - eh.ScrollPage.mobileHeaderHight)) {// should desktop: 65px
        offset -= (windowHeight - eh.ScrollPage.mobileHeaderHight)/2 - elHi/2;
      }
      let elPaBox = $('#eh-page').length > 0? $('#eh-page')[0].getBoundingClientRect() : null;
      let elPaWaBox = $('.eh-page--wrapper').length > 0? $('.eh-page--wrapper')[0].getBoundingClientRect() : null;
      if(elPaWaBox && elPaWaBox.top < 0) {
        offset += Math.min(Math.abs(elPaWaBox.top), eh.ScrollPage.mobileHeaderHight);// should desktop: 65px
      }
      document.documentElement.scrollTop = document.body.scrollTop = offset;
    }

    private static scrollCenterMobile(el: HTMLElement) {
      let offset = el.getBoundingClientRect().top; // offset in elPaWa
      let elHi = el.getBoundingClientRect().height;
      var windowHeight = $(window).height() || 0;
      if(elHi < (windowHeight - eh.ScrollPage.mobileHeaderHight)) {
        offset -= (windowHeight - eh.ScrollPage.mobileHeaderHight)/2 - elHi/2;
      }
      let elPaBox = $('#eh-page').length > 0? $('#eh-page')[0].getBoundingClientRect() : null;
      let elPaWaBox = $('.eh-main--wrapper').length > 0? $('.eh-main--wrapper')[0].getBoundingClientRect() : null;
      if(elPaWaBox && elPaWaBox.top < 0) {
        offset += Math.min(Math.abs(elPaWaBox.top), eh.ScrollPage.mobileHeaderHight);
      }
      document.documentElement.scrollTop = document.body.scrollTop = offset;
    }

    private static scrollCenterLBox(el: HTMLElement) {
      let offset = el.getBoundingClientRect().top;
      let cont = $(el).parents('form.csSnippetForm.ehel-container');
      let scroller = $('.eh-overlay--content-body.eh-scroll-vertical:visible');
      if(cont.length > 0) {
        let off = cont[0].getBoundingClientRect().top;
        // console.log("LB A: ", cont[0].getBoundingClientRect(), " --> ", off, " + ", offset," - ",104);
        offset = Math.abs(offset - off);
        // console.log("LB B: ", offset);
      } 
      scroller.scrollTop(offset);
    }

    private static scrollCenter(elm: HTMLElement) {
      if($('.eh-overlay--content-body.eh-scroll-vertical:visible').length) {
        // lightbox
        Forms.scrollCenterLBox(elm);
      } else {
        const isMobile: boolean = Breakpoints.getInstance().isMobile || $('#eh-page').hasClass('eh-navigation-has-overflow');
        if(isMobile) {
          Forms.scrollCenterMobile(elm);
        } else {
          Forms.scrollCenterDesktop(elm);
        }
      }        
    }

    static init($base: JQuery<HTMLElement>): void {
      $('.conditional_visibility', $base).each((_index, elem) => {
        const $formRow = $(elem);
        const $form: JQuery<HTMLElement> = $formRow.closest('form');
        const $formField = $(':input', $formRow);
        const $watchedField = $($form.find('input[name="' + $formRow.data('conditionFieldName') +'"],select[name="' + $formRow.data('conditionFieldName') +'"]'));
        const targetValue = $formRow.data('conditionFieldValue');
        // console.log('watching ', $watchedField, $formField, 'input[name="' + $formRow.data('conditionFieldName')+'"]');

        if ($watchedField.length > 0 && targetValue !== undefined) {
          $watchedField.on('change', ($event) => {
            let currentValue, valuesMatching;
            let $elem = $($event.currentTarget);
            const formFieldType = $formField.attr('type');
            const type = $elem.attr('type');
            if (type === 'checkbox') {
              $elem = $elem.filter(':checked');
              currentValue = $elem.val();
            }
            else if (type === 'radio') {
              currentValue = $('input[type="radio"][name="' + $elem.attr('name') + '"]:checked').val();
            }
            else {
              currentValue = $elem.val();
            }
            valuesMatching = currentValue === targetValue;
            $formRow.toggle(valuesMatching);
            $formField.prop('disabled', !valuesMatching);
          });
        } else {
          $formRow.hide();
        }
      });

      Forms.elqSetValue($('input[type=hidden].marker-elq-set', $base), 15);

      setTimeout(() => {
        const invalid = $('input.is-invalid,select.is-invalid,textarea.is-invalid', $base).first();
        var el: JQuery<HTMLElement> = $base;
        if (invalid.length === 1) {
          if($('.marker-scroll-into-view-prio:visible', $base).length > 0) {
            $('.marker-scroll-into-view-prio:visible', $base).first().each((idx, elm) => {
              Forms.scrollCenter(elm);
            });
          }
        }
        else {
          $('.marker-scroll-into-view-auto:visible', $base).first().each((_index, elm) => {
            Forms.scrollCenter(elm);
          });
        }
      }, 0);

      $('.marker-agreement', $base).each((_index, elem) => {
        const $el = $(elem);
        const $link = $('a', $el);
        const href = $link.attr('href');
        if (!href) {
          return;
        }
        $link.on('click', ($event) => {
          $event.preventDefault();
          $.ajax({
            'url': href,
            'dataType': 'html'
          }).then(
            (data) => {
              const newElement = $(data);
              const innerContent = newElement.find('.eh-main');
              innerContent.find('.marker-nav-breadbrumb').hide();
              const ov = new eh.Overlay($base);
              ov.putContent(innerContent);
            },
            (err) => {
              window.open(href, '_blank');
            }

          );
        });
      });

      $('.marker-agreement-blank', $base).each((_index, elem) => {
        const $el = $(elem);
        const $link = $('a', $el);
        const href = $link.attr('href');
        if (!href) {
          return;
        }
        $link.on('click', ($event) => {
          $event.preventDefault();
              window.open(href, '_blank');
        });
      });

      /**
       * disable form after submission.
       * class <code>is-loading</code> will disable pointer-events and swaps
       * a busy-indicator with the label to reflect the loading state.
       *
       * this mechanism requires special markup like shown in <code>inputs.html</code>.
       *
       * e.g.
       * ```
       <button type="submit" class="eh-link eh-button eh-cta-button--02 eh-layout--align-center eh-layout--pos-rel eh-load-ctrl eh-load-wrapper marker-prevent-multiple-submit">
         <span class="eh-button-label">Submit</span>
         <img class="eh-busy-spinner" src="./images/logo/spinner.svg" alt="please wait">
       </button>
       * ```
       */
      $('.marker-prevent-multiple-submit', $base).each((_index, submit: HTMLButtonElement) => {
        if (!submit.form) {
          throw new Error('missing form element');
        }
        submit.form.addEventListener('submit', e => {
          if (!!submit.form?.getAttribute('transmitted')) {
            e.stopImmediatePropagation();
            e.preventDefault();
            return false;
          }
          submit.classList.add('is-loading');
          submit.form?.setAttribute('transmitted', 'true');
        });
      });

      function resetError($fset: JQuery<HTMLElement>, $wrap: JQuery<HTMLElement>) {
          $fset.removeClass('ehtw-form--error');
          $fset.children('.ehtw-form--error-message').addClass('ehtw-hidden');
          $wrap.children('.ehtw-form--error-message').addClass('ehtw-hidden');
      }

      $('textarea.is-invalid, input.is-invalid, select.is-invalid, input[type=radio], input[type=checkbox]', $base).each((_idx, elem) => {
        const $el = $(elem);
        const $wrap = $el.selfOrClosest('.ehel-form--fieldset-checkbox');
        const $fset = $el.selfOrClosest('fieldset');
        if($el.selfOrClosest('fieldset').hasClass('ehtw-form--error')) {
          $el.on('change', (_index, elem) => {
            resetError($fset, $wrap);
          });
        }
      });

      $('input[type="radio"]').on('click', ($event) => {
        $event.target.focus()
      });

      $('input[type="checkbox"]').on('click', ($event) => {
        $event.target.focus()
      });

      $('.ehel-form').on( 'mousedown', 'input,textarea,.select2', function(event) {
        event.currentTarget.classList.add('focus-click');
      });

      $('.ehel-form').on( 'mousedown', 'label', function(event) {
        setTimeout(() => {
          const n = document.getElementById(event.currentTarget.getAttribute('for'));
          if(n) {
              n.classList.add('focus-click');
            }
          }, 10);
      });
      
      $('.ehel-form').on("focus.select2", 'select', function(event) {
        setTimeout(() => {
          $(event.target).select2('open');
          $(event.target).siblings('.select2').addClass('focus-click');
        },10);
      });

      $('.ehel-form').on("select2:clear", 'select', function(event) {
        setTimeout(() => {
          $('select').select2('close');
        }, 10);
      });

      $('.ehel-form').on('blur', 'input,textarea,.select2', function(event) {
        event.currentTarget.classList.remove('focus-click');
        event.currentTarget.classList.remove('select2-container--focus');
      });
      
      $('.ehel-form').on("select2:open", 'select', function(event) {
        $('input.select2-search__field').addClass('focus-click');
      });

      $('.ehel-form').on("select2:close", 'select', function(event) {
        $(event.target).siblings('.select2').removeClass('focus-click');
        $(event.target).siblings('.select2').removeClass('select2-container--focus');
        $(event.target).siblings('.select2').blur();
      });

      $('body').on('click', function(event) {
        if (!event.target.classList.toString().includes('select2')) {
          $('.select2').removeClass('focus-click');
        }
      });
    }

  }
}
