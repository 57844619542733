namespace eh {
  
  export class ProductComparison {
    
    private static readonly EVENT_TYPE__UPDATED = 'updated';
    private static readonly LOCAL_STORAGE_KEY__LIST = 'eh.ProductComparison.list';
    private static readonly MAX_ITEMS = 3;
    
    private list: { [key: string]: string };
    public isInitializing = false;
    
    static getInstance(): ProductComparison | undefined {
      return $(':root').data('eh.ProductComparison');
    }
    
    static init($base: JQuery<HTMLElement>, isInSnippet:boolean): void {
      if(isInSnippet) {
        return;
      }
      const pc = new ProductComparison();
        
        $(':root').data('eh.ProductComparison', pc)
            .on(eh.ProductComparison.EVENT_TYPE__UPDATED, ($event: JQuery.Event, items: any[], canAdd: boolean, initial: boolean) => {
          pc.isInitializing = initial;
          $('.trigger-product-comparison-change').each((_index, elem) => {
            
            const $checkbox = $(elem),
                productId = $checkbox.closest('*[data-cs-product-material-number]').data('csProductMaterialNumber');
            if (productId) {
              const check = productId in items,
                enabled = check || canAdd;
              $checkbox.prop('checked', check)
                .prop('disabled', !enabled)
                .closest('.tooltip').each(() => {
                $(elem).toggleClass('disabled', !enabled);
                if (enabled) {
                  $(elem).removeData('tooltipDisabled');
                }
                else {
                  $(elem).data('tooltipDisabled', $(elem).data('tooltipDisabledTpl'));
                }
              });
              $checkbox.closest('.eh-checkbox').toggleClass('disabled', !enabled);
            }
          });

          const numProductRoots = pc.getProductRoots().length;

          // apply extra space to prevent overlapping of .eh-footer-bar with main content and update placement when done
          $('.eh-main').toggleClass('eh-has-footer-bar', numProductRoots > 0);

          requestAnimationFrame(_ => {
            const $label = $('.eh-footer-bar').toggleClass('eh-display-none', numProductRoots === 0).find('.eh-footer-bar--amount-label'),
                  labelTemplate = $label.data(numProductRoots === 1 ? 'labelTemplateSingular' : 'labelTemplatePlural');
            if (labelTemplate) {
              $label.text(labelTemplate.replace('00000', numProductRoots));
            }

            $('.trigger-product-comparison-compare').toggleClass('disabled', numProductRoots < 2);
            pc.isInitializing = false;
          });


        });
        
        const initEventHandling = ($base: JQuery<HTMLElement>) => {
          $('.trigger-product-comparison-change', $base).on('change', ($event) => {
            if (pc.isInitializing) {
              return;
            }
            const $cb = $($event.currentTarget),
                productId = $cb.closest('*[data-cs-product-material-number]').data('csProductMaterialNumber'),
                productRoot = $cb.closest('*[data-cs-product-root]').data('csProductRoot');
            if (productId) {
              let eventSubtype: string;
              if ($cb.prop('checked')) {
                pc.add(productId, productRoot);
                eventSubtype = 'check';
              }
              else {
                pc.remove(productId);
                eventSubtype = 'uncheck';
              }
              eh.Tracking.injectTrackingEvent({'event_subtype': eventSubtype}, $cb, 'checkbox');
              $(':root').trigger(STICKY_EVENTS.DOM_MUTATION);
            }
          });
        };
        
        initEventHandling($base);

        $(':root').on(cs.Snippet.EventIdPostReplace, (event: cs.SnippetEventPostReplace) => {
          initEventHandling(event.replacedTarget);
        });


      $('.trigger-product-comparison-compare').on('mousedown', $event => {
          const $btn = $($event.currentTarget),
              currentHref = $btn.attr('href'),
              items = pc.getProductRoots()
          ;
          if (items.length < 2) {
            if (currentHref) {
              $btn.data('href', currentHref);
              $btn.removeAttr('href');
            }
            return;
          }
          else if (!currentHref) {
            $btn.attr('href', $btn.data('href'));
          }
          
          let currentQueryString = $btn.prop('search') || '';
          const newParams = items.map((v, i) => {
                return [encodeURIComponent('prod' + i), encodeURIComponent(v)].join('=');
              })
          ;
          if (currentQueryString[0] === '?') {
            currentQueryString = currentQueryString.substring(1);
          }
          
          $btn.prop('search', '?' + currentQueryString.split('&')
            .map((kv: string) => {
            const kvParts = kv.split('=');
            if (kvParts.length === 2 && decodeURIComponent(kvParts[0]).match(/^prod[0-9]+$/)) {
              return null;
            }
            return kv;
          }).filter((v: string) => {
            return v !== null;
          }).concat(newParams).join('&'));
        });
        
        $('.trigger-product-comparison-clear-all').on('click', () => {
          pc.removeAll();
        });
        
        pc.fireUpdateEvent(true);
    }
    
    constructor() {
      this.list = ProductComparison.loadList();
    }
    
    add(productId: string, productRoot: string) {
      if (!(productId in this.list) && Object.keys(this.list).length < eh.ProductComparison.MAX_ITEMS) {
        this.list[productId] = productRoot;
        ProductComparison.storeList(this.list);
        this.fireUpdateEvent(false);
      }
    }
    
    remove(productId: string) {
      if (productId in this.list) {
        delete this.list[productId];
        ProductComparison.storeList(this.list);
        this.fireUpdateEvent(false);
      }
    }
    
    removeAll() {
      if (Object.keys(this.list).length > 0) {
        this.list = {};
        ProductComparison.storeList(this.list);
        this.fireUpdateEvent(false);
      }
    }
    
    getProductRoots() {
      let productRoots: { [key: string]: boolean } = {};
      Object.keys(this.list).forEach((key, i) => productRoots[this.list[key]] = true);
      return Object.keys(productRoots);
    }
    
    private fireUpdateEvent(initial: boolean, $base: JQuery<HTMLElement> = $(':root')) {
      const items = this.list;
      const extraParameters: any[] = [items, Object.keys(items).length < eh.ProductComparison.MAX_ITEMS, initial || false];
      $base.trigger(eh.ProductComparison.EVENT_TYPE__UPDATED, extraParameters);
    }
    
    private static loadList() {
      try {
        if (window.sessionStorage) {
          return JSON.parse(window.sessionStorage.getItem(eh.ProductComparison.LOCAL_STORAGE_KEY__LIST) || '{}');
        }
      }
      catch (e) {
      }
      return {};
    }
    
    private static storeList(list: { string?: boolean }) {
      try {
        if (window.sessionStorage) {
          window.sessionStorage.setItem(eh.ProductComparison.LOCAL_STORAGE_KEY__LIST, JSON.stringify(list));
        }
      }
      catch (e) {
      }
    }

  }
  
}
